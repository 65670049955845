import React from 'react';
import PropTypes from 'prop-types';

const ExternalLink = ({
    href = '',
    display = '',
    toggleMainMenu = () => {},
}) => (
    <a
        href={href}
        target='_blank'
        rel='noopener noreferrer'
        onClick={() => toggleMainMenu()}
    >
        <span>{display}</span>
    </a>
);

ExternalLink.propTypes = {
    href: PropTypes.string,
    display: PropTypes.string,
    toggleMainMenu: PropTypes.func,
};

export default ExternalLink;
