import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { lang } from '../../il8n/lang';

const InternalLink = ({
    href = '',
    display = '',
    toggleMainMenu = () => {},
}) => (
    <Link to={`/${lang.getLanguage()}${href}`} onClick={() => toggleMainMenu()}>
        <span>{display}</span>
    </Link>
);

InternalLink.propTypes = {
    href: PropTypes.string,
    display: PropTypes.string,
    toggleMainMenu: PropTypes.func,
};

export default InternalLink;
