import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { toggleInterstitial } from '../../Actions/Index';
import { lang } from '../../il8n/lang';

import './Interstitial.css';

/**
 * Interstitial Component.
 * @constructor
 * @prop {json} interstitial - A redux driven JSON representation of this interstitials state and content.
 */

class Interstitial extends Component {
    /**
     * Navigate and track a links exit
     */
    navigateAndTrack() {
        let { interstitial } = this.props;
        ReactGA.event({
            category: 'Retailer',
            action: 'Click',
            label: interstitial.label,
        });
        window.open(interstitial.url, '_blank');
        this.props.dispatch(toggleInterstitial(false, '', ''));
    }
    /**
     * Get interstitial content.
     * @return {jsx} The interstitial content.
     */
    getContent() {
        let { interstitial } = this.props;
        if (interstitial.active) {
            return (
                <div className='interstitial'>
                    <div className='interstitial-content'>
                        <h2>{lang.interstitial.title}</h2>
                        <p>{lang.interstitial.copy}</p>
                        <div className='interstial-links'>
                            <a onClick={() => this.navigateAndTrack()}>
                                {lang.interstitial.continue}
                            </a>
                            <a
                                onClick={() =>
                                    this.props.dispatch(
                                        toggleInterstitial(false, '', ''),
                                    )
                                }
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {lang.interstitial.cancel}
                            </a>
                        </div>
                    </div>
                </div>
            );
        }
    }
    render() {
        return <div>{this.getContent()}</div>;
    }
}

function mapStateToProps(state) {
    return {
        lang: state.lang,
        interstitial: state.interstitial,
    };
}

Interstitial.propTypes = {
    dispatch: PropTypes.func,
    interstitial: PropTypes.object,
};

export default connect(mapStateToProps)(Interstitial);
