export const cdnBase = 'https://gabby-dollhouse.s3.amazonaws.com/';
export const atgBase = 'http://dock.spinmasterstudios.com:9092/';

export const atgBrand = 'cat_spinmaster_games';
export const salsifyBrand = 'cat_spinmaster_games';

export const atgProductDetailRoot = 'https://shop.spinmaster.com/product/';
export const UA = 'UA-102631505-50';
export const collectionServer =
    'http://lampclusterlarge.spinmasterstudios.com:1337/collection/candylocks/';

export const breakXSmall = 540;

export const breakSmall = 768;

export const breakTablet = 992;

export const breakLaptop = 1200;

export const breakXLaptop = 1600;

export const breakXLarge = 1600;

// To add a new locale, you must add it on this list
export const siteMapByLocale = {
    en_us: 'us',
    fr_fr: 'fr'
};


// If you wish to review locales but not have them appear in the menu, add them here.
export const blockedLocales = [];
