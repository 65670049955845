export const CHANGE_LANG = 'CHANGE_LANG';
export const TOGGLE_INTERSTITIAL = 'TOGGLE_INTERSTITIAL';
export const TOGGLE_GA = 'TOGGLE_GA';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';

export function changeLanguage(lang) {
    return {
        type: CHANGE_LANG,
        lang,
    };
}

export function toggleGa(active, status) {
    return {
        type: TOGGLE_GA,
        gaToggle: {
            active,
            status,
        },
    };
}

export function toggleModal(active) {
    return {
        type: TOGGLE_MODAL,
        modalToggle: {
            active,
        },
    };
}

export function toggleInterstitial(active, url) {
    return {
        type: TOGGLE_INTERSTITIAL,
        interstitial: {
            active,
            url,
        },
    };
}
