import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toggleInterstitial, toggleModal } from '../../Actions/Index';

export const ToggleModalLink = ({
    display = '',
    toggleMainMenu = () => {},
    toggleModal = () => {},
}) => (
    <button
        onClick={() => {
            toggleMainMenu();
            toggleModal(true);
        }}
        rel='noopener noreferrer'
    >
        <span>{display}</span>
    </button>
);

ToggleModalLink.propTypes = {
    href: PropTypes.string,
    display: PropTypes.string,
    toggleModal: PropTypes.func,
    toggleMainMenu: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        modal: state.toggleModal,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleInterstitial: (active, url) => {
            dispatch(toggleInterstitial(active, url));
        },
        toggleModal: active => {
            dispatch(toggleModal(active));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToggleModalLink);
