import React from 'react';
import PropTypes from 'prop-types';
import { HashLink } from 'react-router-hash-link';

import { lang } from '../../il8n/lang';

const scrollWithOffset = (el, offset) => {
    const elementPos = el.offsetTop - offset;
    window.scroll({
        top: elementPos,
        left: 0,
        behavior: 'smooth',
    });
};

const HashedLink = ({ href = '', display = '', toggleMainMenu = () => {} }) => (
    <HashLink
        smooth
        to={`/${lang.getLanguage()}${href}`}
        onClick={() => toggleMainMenu()}
        scroll={el => scrollWithOffset(el, 150)}
    >
        <span>{display}</span>
    </HashLink>
);

HashedLink.propTypes = {
    href: PropTypes.string,
    display: PropTypes.string,
    toggleMainMenu: PropTypes.func,
};

export default HashedLink;
