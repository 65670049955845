import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

// Common Components
import MainMenu from '../components/Navs/MainMenu/MainMenu';
import Footer from '../components/Navs/Footer/Footer';
import Interstitial from '../components/Interstitial/Interstitial';
import Gatoggle from '../components/Gatoggle/Gatoggle';

import { lang } from '../il8n/lang';

import './App.css';

/**
 * Main App Wrapper - This class wraps the entire application. It handles the proper assignment of the localized subdir on mount. It is also home to any common components.
 * @constructor
 */
class App extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <html lang={lang.getLanguage()} />
                </Helmet>
                <Gatoggle />
                <Interstitial />
                <MainMenu collapse={true} centered={false} />
                <div className='content'>{this.props.children}</div>
                <Footer />
            </div>
        );
    }
}

App.propTypes = {
    children: PropTypes.element,
};

export default App;
