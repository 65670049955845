import React from 'react';
import PropTypes from 'prop-types';

import InternalLink from './InternalLink';
import HashedLink from './HashedLink';
import ExternalLink from './ExternalLink';
import InterstitialLink from './InterstitialLink';
import ToggleModalLink from './ToggleModalLink';

import './LinkType.css';

const linkTypes = {
    internal: InternalLink,
    internal_hash: HashedLink,
    external: ExternalLink,
    interstitial: InterstitialLink,
    modal: ToggleModalLink,
};

const LinkType = ({
    type = 'internal',
    href = '',
    display = '',
    toggleMainMenu = () => {},
    ...props
}) => {
    const LinkType = linkTypes[type];
    return (
        <LinkType
            href={href}
            display={display}
            toggleMainMenu={toggleMainMenu}
            {...props}
        />
    );
};

LinkType.propTypes = {
    type: PropTypes.string,
    href: PropTypes.string,
    display: PropTypes.string,
    toggleMainMenu: PropTypes.func,
};

export default LinkType;
