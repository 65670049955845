import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import logoSM from './Images/logo-sm.png';

import LinkType from '../../LinkType/LinkType';

import { lang } from '../../../il8n/lang';

import './Footer.css';

const Footer = () => {
    const year = new Date().getFullYear();
    const {
        footer: { brand = '', copy = '', links = [] },
    } = lang;

    return (
        <footer className='footer'>
            <div className='wrap'>
                <a
                    className='footer__logo'
                    href='http://www.spinmaster.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <img src={logoSM} alt={brand} />
                </a>
                <div className='footer__links'>
                    {links &&
                        links.map(({ type, href, display }, index) => (
                            <LinkType
                                key={index}
                                type={type}
                                href={href}
                                display={display}
                            />
                        ))}
                </div>
                <div className='footer__copyright'>{`© ${year} ${copy}`}</div>
            </div>
        </footer>
    );
};

function mapStateToProps(state) {
    return { lang: state.lang };
}

Footer.propTypes = {
    dispatch: PropTypes.func,
};

export default connect(mapStateToProps)(Footer);
