import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { lang } from '../../il8n/lang';
import { connect } from 'react-redux';
import { toggleGa } from '../../Actions/Index';
import { UA } from '../../const';

// import ReactGA from 'react-ga';
import './Gatoggle.css';

/**
 * Gatoggle Component.
 * @constructor
 * @prop {json} interstitial - A redux driven JSON representation of this interstitials state and content.
 */

class Gatoggle extends Component {
    disable() {
        window['ga-disable-GA_MEASUREMENT_ID'] = true;
    }
    /**
     * Get interstitial content.
     * @return {jsx} The interstitial content.
     */
    getContent() {
        let { gaToggle } = this.props;
        if (gaToggle.active) {
            return (
                <div className='interstitial'>
                    <div className='interstitial-content'>
                        <p>{lang.ga.copy}</p>
                        <div className='interstial-links'>
                            <a
                                onClick={() =>
                                    this.props.dispatch(toggleGa(false, true))
                                }
                            >
                                {lang.ga.continue}
                            </a>
                            <a
                                onClick={() => {
                                    this.props.dispatch(toggleGa(false, false));
                                    window[`ga-disable-${UA}`] = true;
                                }}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {lang.ga.disable}
                            </a>
                        </div>
                    </div>
                </div>
            );
        }
    }
    render() {
        return <div>{this.getContent()}</div>;
    }
}

function mapStateToProps(state) {
    return {
        lang: state.lang,
        gaToggle: state.gaToggle,
    };
}

Gatoggle.propTypes = {
    gaToggle: PropTypes.object,
    dispatch: PropTypes.func,
};

export default connect(mapStateToProps)(Gatoggle);
