// eslint-disable-next-line no-unused-vars
import { cdnBase } from '../../const';

export default {
    fr_fr: {
        logoSrc: `${cdnBase}gabby-dollhouse-logo-fr.png`,
        langKeys: {
            en_us: 'English (US)',
            fr_fr: 'French'
        },
        interstitial: {
            heading: 'Quitter ce site',
            copy: `En cliquant sur "Continuer", vous quitterez ce site Web et arriverez sur un site tiers, qui est régi par des conditions et des politiques de confidentialité différentes.`,
            continue: 'Continuer',
            cancel: 'Annuler',
        },
        ga: {
            copy:
                `Nous utilisons des cookies sur ce site pour améliorer votre expérience. En utilisant ce site, vous consentez à l'utilisation de cookies. Notre Politique de Cookies explique ce que sont les cookies, comment nous utilisons les cookies, comment les tiers avec lesquels nous pouvons nous associer peuvent utiliser des cookies sur le Service, vos choix concernant les cookies et des informations supplémentaires sur les cookies.`,
            continue: 'Continuer',
            disable: 'Désactiver les cookies',
        },
        brand: 'Gabby et la maison magique',
        applinks: {
            apple: 'LINK TO APPLE APP',
            google: 'LINK TO GOOGLE APP',
        },
        chooseRegion: 'Choose Language',
        loading: 'Loading',
        select: 'Select',
        clearAll: 'Clear All',
        filters: 'Filters',
        details: 'Details',
        instructions: 'download Instructions',
        videoInstructions: 'video Instructions',
        warnings: {
            enableIframes: 'You Must Enable iFrames to view this video',
        },
        helmet: {
            siteTitle: " | Gabby et la maison magique",
            meta_description: `Jouez l'appli Gabby et la maison magique`,
            tags: `spin;master;`,
        },
        footer: {
            brand: 'Spin Master',
            copy: 'Spin Master Ltd. All Rights Reserved.',
            links: [
                {
                    type: 'external',
                    href:
                        'https://www.spinmaster.com/en-US/contact-us',
                    display: 'Contact Us',
                },
                {
                    type: 'external',
                    href:
                        'https://www.spinmaster.com/en-US/legal/privacy-policy',
                    display: 'Privacy Policy',
                },
                {
                    type: 'external',
                    href:
                        'https://www.spinmaster.com/en-US/legal/terms-of-use',
                    display: 'Terms of Service',
                },
            ],
        },
        home: {
            title: 'Appli',
            heroImage: `${cdnBase}5-Overworld_AsShownInGame_Text.jpg`,
            slides: [
                {
                    img: `${cdnBase}1-Gabbys-Dollhouse.jpg`,
                    img2x: `${cdnBase}1-Gabbys-Dollhouse.jpg`,
                },
                {
                    img: `${cdnBase}2-Gabbys-Dollhouse.jpg`,
                    img2x: `${cdnBase}2-Gabbys-Dollhouse.jpg`,
                },
                {
                    img: `${cdnBase}3-Gabbys-Dollhouse.jpg`,
                    img2x: `${cdnBase}3-Gabbys-Dollhouse.jpg`,
                },
                {
                    img: `${cdnBase}4-Gabbys-Dollhouse.jpg`,
                    img2x: `${cdnBase}4-Gabbys-Dollhouse.jpg`,
                },
                {
                    img: `${cdnBase}5-Gabbys-Dollhouse.jpg`,
                    img2x: `${cdnBase}5-Gabbys-Dollhouse.jpg`,
                },
            ],
            promoblock: {
                imageUrl: `${cdnBase}app-icon.png`,
                title: 'Bienvenue dans la Maison Magique de Gabby !',
                bulletPoints: [
                    `Jouez à plus de 30 jeux avec Gabby et ses amis-chats : Pandy Pattes, P’tit Chou, Marine, DJ Miaou, Bébé Boîte, Chabriolette, Fée Minette et Polochat.`,
                    `Chant, peinture, cuisine, jardinage, bricolage : Amusez-vous avec Gabby à vos côtés. Découvrez les pièces colorées aux couleurs des personnages chats et vivez les aventures de Gabby comme dans le dessin animé.`,
                ]
            },
            downloads: [
                {
                    image: `${cdnBase}button-apple-store-fr-3.png`,
                    url: 'http://apps.apple.com/us/app/gabbys-dollhouse/id1488385103',
                    label: 'apple'
                },
                {
                    image: `${cdnBase}button-google-fr-3.png`,
                    url: 'https://play.google.com/store/apps/details?id=com.spinmaster.gabbysdollhouse',
                    label: 'google'
                },
                {
                    image: `${cdnBase}button-amazon-store-fr-3.png`,
                    url: 'https://www.amazon.com/Spinmaster-Studios-Gabbys-Dollhouse/dp/B08JPB2CX2',
                    label: 'amazon'
                },
            ]
        },
        support: {
            title: 'Support',
            subTitle: 'Support',
        },
        nomatch: {
            title: 'Page Not Found',
        },
    },
};
