// eslint-disable-next-line no-unused-vars
import { cdnBase } from '../../const';

export default {
    en_us: {
        logoSrc: `${cdnBase}gabby-dollhouse-logo.png`,
        langKeys: {
            en_us: 'English (US)',
        },
        interstitial: {
            heading: 'Leaving this Site',
            copy: `By clicking "continue" you'll be leaving this web site and landing on a third-party site, which is governed by different terms and privacy policies.`,
            continue: 'Continue',
            cancel: 'Cancel',
        },
        ga: {
            copy:
                'We use cookies on this site to help improve your experience. By using this site, you consent to the use of cookies. Our Cookies Policy explains what cookies are, how we use cookies, how third­parties we may partner with may use cookies on the Service, your choices regarding cookies and further information about cookies.',
            continue: 'Continue',
            disable: 'Disable Cookies',
        },
        brand: 'Gabby\'s Dollhouse',
        applinks: {
            apple: 'LINK TO APPLE APP',
            google: 'LINK TO GOOGLE APP',
        },
        chooseRegion: 'Choose Language',
        loading: 'Loading',
        select: 'Select',
        clearAll: 'Clear All',
        filters: 'Filters',
        details: 'Details',
        instructions: 'download Instructions',
        videoInstructions: 'video Instructions',
        warnings: {
            enableIframes: 'You Must Enable iFrames to view this video',
        },
        helmet: {
            siteTitle: " | Gabby's DollHouse",
            meta_description: 'A Spin Master Brand website',
            tags: `spin;master;`,
        },
        footer: {
            brand: 'Spin Master',
            copy: 'Spin Master Ltd. All Rights Reserved.',
            links: [
                {
                    type: 'external',
                    href:
                        'https://www.spinmaster.com/en-US/contact-us',
                    display: 'Contact Us',
                },
                {
                    type: 'external',
                    href:
                        'https://www.spinmaster.com/en-US/legal/privacy-policy',
                    display: 'Privacy Policy',
                },
                {
                    type: 'external',
                    href:
                        'https://www.spinmaster.com/en-US/legal/terms-of-use',
                    display: 'Terms of Service',
                },
            ],
        },
        home: {
            title: 'Home',
            heroImage: `${cdnBase}5-Overworld_AsShownInGame_Text.jpg`,
            slides: [
                {
                    img: `${cdnBase}1-Gabbys-Dollhouse.jpg`,
                    img2x: `${cdnBase}1-Gabbys-Dollhouse.jpg`,
                },
                {
                    img: `${cdnBase}2-Gabbys-Dollhouse.jpg`,
                    img2x: `${cdnBase}2-Gabbys-Dollhouse.jpg`,
                },
                {
                    img: `${cdnBase}3-Gabbys-Dollhouse.jpg`,
                    img2x: `${cdnBase}3-Gabbys-Dollhouse.jpg`,
                },
                {
                    img: `${cdnBase}4-Gabbys-Dollhouse.jpg`,
                    img2x: `${cdnBase}4-Gabbys-Dollhouse.jpg`,
                },
                {
                    img: `${cdnBase}5-Gabbys-Dollhouse.jpg`,
                    img2x: `${cdnBase}5-Gabbys-Dollhouse.jpg`,
                },
            ],
            promoblock: {
                imageUrl: `${cdnBase}app-icon.png`,
                title: 'Welcome to Gabby’s Dollhouse!',
                bulletPoints: [
                    'Play more than 30 games with Gabby and her cat friends: Pandy, Cakey, Mercat, DJ Catnip, Baby Box, Carlita, Kitty Fairy and Pillow Cat.',
                    'Sing, paint, cook, plant, craft and have fun with Gabby at your side. Discover colorful cat-themed rooms and make as many fun experiments as you like.',
                ]
            },
            downloads: [
                {
                    image: `${cdnBase}button-apple-store.svg`,
                    url: 'http://apps.apple.com/us/app/gabbys-dollhouse/id1488385103',
                    label: 'apple'
                },
                {
                    image: `${cdnBase}button-google-store.svg`,
                    url: 'https://play.google.com/store/apps/details?id=com.spinmaster.gabbysdollhouse',
                    label: 'google'
                },
                {
                    image: `${cdnBase}button-amazon-store.svg`,
                    url: 'https://www.amazon.com/Spinmaster-Studios-Gabbys-Dollhouse/dp/B08JPB2CX2',
                    label: 'amazon'
                },
            ]
        },
        support: {
            title: 'Support',
            subTitle: 'Support',
        },
        nomatch: {
            title: 'Page Not Found',
        },
    },
};
