import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toggleInterstitial } from '../../Actions/Index';

const InterstitialLink = ({
    href = '',
    display = '',
    toggleMainMenu = () => {},
    toggleInterstitial = () => {},
    ...props
}) => {
    return (
        <button
            onClick={() => {
                toggleMainMenu();
                toggleInterstitial(true, href);
            }}
            {...props}
        >
            <span>{display}</span>
        </button>
    );
};

InterstitialLink.propTypes = {
    href: PropTypes.string,
    display: PropTypes.string,
    toggleInterstitial: PropTypes.func,
    toggleMainMenu: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
    return {
        toggleInterstitial: (active, url) => {
            dispatch(toggleInterstitial(active, url));
        },
    };
};

export default connect(null, mapDispatchToProps)(InterstitialLink);
