import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { HashLink } from 'react-router-hash-link';

import { breakLaptop } from '../../../const';
import { toggleInterstitial } from '../../../Actions/Index';
import { lang } from '../../../il8n/lang';

import './MainMenu.css';

/**
 * The main application menu.
 * @constructor
 Props:

  - collaspe(boolean): toggle collapsing behaviour
  - centered(boolean): change placement of logo
 */

class MainMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            menuClass: '',
            lastScrollTop: 0,
            activeClass: 'active',
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.renderMenu = this.renderMenu.bind(this);
    }

    componentDidMount() {
        // When this component mounts, begin listening for scroll changes
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        // If this component is unmounted, stop listening
        window.removeEventListener('scroll', this.handleScroll);
    }

    /**
     * handle scroll
     * @param {event} event
     */

    handleScroll(e) {
        if (this.state.collapse) {
            if (this.state.menuClass !== 'active') {
                const currentScrollTop = document.documentElement.scrollTop;

                // Set the state of hidden depending on scroll position
                // We only change the state if it needs to be changed
                if (
                    currentScrollTop > 300 &&
                    currentScrollTop > this.state.lastScrollTop
                ) {
                    this.setState({ menuClass: 'mod-hidden' });
                } else if (this.state.menuClass === 'mod-hidden') {
                    this.setState({ menuClass: '' });
                }
                this.setState({ lastScrollTop: currentScrollTop });
            }
        }
    }
    /**
     * Toggles the main menu.
     * @param {string} requestor - Which element was clicked to initiate this call.
     */
    toggleMainMenu(togglesetting) {
        let { menuClass } = this.state;
        if (window.innerWidth <= breakLaptop) {
            if (menuClass === 'active') {
                this.setState({
                    menuClass: '',
                });
                this.setState({
                    toggleicon: 'hide',
                });
            } else {
                if (togglesetting !== 'nomobile') {
                    this.setState({
                        menuClass: 'active',
                    });

                    this.setState({
                        toggleicon: 'active',
                    });
                }
            }
        }
    }

    /**
     * Renders either the centered layout or left aligned layout
     * @returns {jsx} - The nav item
     */
    renderMenu() {
            return (
                <div className='main-menu'>
                    <Link
                        to={`/${lang.getLanguage()}`}
                        className='main-menu__logo'
                        onClick={() => this.toggleMainMenu('nomobile')}
                    >
                        <img className='mainlogo' src={lang.logoSrc} alt={lang.brand} />
                    </Link>
                </div>
            );
    }
    /**
     * Gets the nav item. Supports 4 types in nav.type : 'internal','internal_hash','external','interstitial' & 'function'.
     * @param {int} index - Current item index.
     * @param {json} nav - Nav item properties.
     * @returns {jsx} - The nav item
     */
    getNavItem(index, nav) {
        let { retailersModal } = this.props;
        let mobileOnly = nav.mobileOnly ? 'mobile' : '';
        switch (nav.type) {
            case 'internal':
                return (
                    <li key={`navigation-${index}`} className={mobileOnly}>
                        <Link
                            to={`/${lang.getLanguage()}${nav.href}`}
                            onClick={() => this.toggleMainMenu()}
                        >
                            <span>{nav.display}</span>
                        </Link>
                    </li>
                );
            case 'internal_hash':
                return (
                    <li key={`navigation-${index}`} className={mobileOnly}>
                        <HashLink
                            smooth
                            to={`/${lang.getLanguage()}${nav.href}`}
                            onClick={() => this.toggleMainMenu()}
                        >
                            <span>{nav.display}</span>
                        </HashLink>
                    </li>
                );
            case 'external':
                return (
                    <li key={`navigation-${index}`} className={mobileOnly}>
                        <a
                            href={nav.href}
                            target='_blank'
                            rel='noopener noreferrer'
                            onClick={() => this.toggleMainMenu()}
                        >
                            <span>{nav.display}</span>
                        </a>
                    </li>
                );
            case 'interstitial':
                return (
                    <li key={`navigation-${index}`} className={mobileOnly}>
                        <a
                            onClick={() => {
                                this.props.dispatch(
                                    toggleInterstitial(true, nav.href),
                                );
                                this.toggleMainMenu();
                            }}
                            rel='noopener noreferrer'
                        >
                            <span>{nav.display}</span>
                        </a>
                    </li>
                );
            case 'function':
                return (
                    <li key={`navigation-${index}`} className={mobileOnly}>
                        <a onClick={retailersModal} rel='noopener noreferrer'>
                            <span>{nav.display}</span>
                        </a>
                    </li>
                );
            default:
                console.error(new Error('Nav items must specify type.'));
        }
    }

    render() {
        let { menuClass } = this.state;
        return (
            <header className={menuClass}>
                <div className='wrap'>{this.renderMenu()}</div>
            </header>
        );
    }
}

function mapStateToProps(state) {
    return { lang: state.lang };
}

MainMenu.propTypes = {
    retailersModal: PropTypes.any,
    dispatch: PropTypes.func,
};

MainMenu.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(MainMenu);
