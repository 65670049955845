import { lang } from '../il8n/lang';

const initialState = {
    lang: lang.getLanguage(),
    interstitial: {
        active: false,
        url: '',
        label: '',
    },
    gaToggle: {
        active: true,
        status: true,
    },
    modalToggle: {
        active: false,
    },
};

const mainReducer = (state = {}, action) => {
    switch (action.type) {
        case 'CHANGE_LANG':
            lang.setLanguage(action.lang);
            return Object.assign({}, state, {
                lang: action.lang,
            });
        case 'TOGGLE_GA':
            return Object.assign({}, state, {
                gaToggle: {
                    active: action.gaToggle.active,
                    status: action.gaToggle.status,
                },
            });
        case 'TOGGLE_MODAL':
            return Object.assign({}, state, {
                modalToggle: {
                    active: action.modalToggle.active,
                },
            });
        case 'TOGGLE_INTERSTITIAL':
            return Object.assign({}, state, {
                interstitial: {
                    active: action.interstitial.active,
                    url: action.interstitial.url,
                    label: action.interstitial.label,
                },
            });
        default:
            return initialState;
    }
};

export default mainReducer;
