import LocalizedStrings from 'react-localization';

/* eslint-disable camelcase */
import en_us from './en_us';
import fr_fr from './fr_fr';

export const lang = new LocalizedStrings({
    ...en_us,
    ...fr_fr
});
